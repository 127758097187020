import axios from "axios";
import moment from "moment";

import { serverConfig } from "../config";
/* Helpers */
import { getFiltersString } from "utils/helpers";
/* Services */
import employeeService from "services/employee";

const apiUrl = serverConfig.apiUrl;

const jobService = {
  getAllJobs: (
    token,
    tenantUuid,
    key,
    limit,
    page,
    filtersString,
    isArchive = "False",
    sortType = "sort",
    isExport,
    query = {}
  ) => {
    let headers = { headers: { Authorization: `JWT ${token}` } };
    if (isExport && (isExport == "True" || isExport == "False"))
      headers.responseType = "blob";

    return axios.get(
      `${apiUrl}/api/jobs/sub-job-with-archived/light/${tenantUuid}/?${
        key ? `${sortType}=${key}&` : ""
      }is_archived=${isArchive}${limit ? `&page_size=${limit}` : ""}${
        page ? `&page=${page}` : ""
      }${filtersString ? filtersString : ""}` +
        `${isExport ? `&is_excel_export=${isExport}` : ""}` +
        `${query.columns ? `&excel_properties=${query.columns}` : ``}` +
        `${
          query.columnHeaders
            ? `&excel_column_headers=${query.columnHeaders}`
            : ``
        }`,
      headers
    );
  },
  exportAllJobs: (
    token,
    key,
    filtersString = {},
    isArchive = "False",
    sortType = "sort",
    payload
  ) => {
    filtersString =
      typeof filtersString === "object"
        ? getFiltersString(filtersString)
        : filtersString;

    return axios.post(
      `${apiUrl}/api/jobs/jobs/export/?${
        key ? `${sortType}=${key}` : ""
      }&is_archived=${isArchive}${filtersString ? filtersString : ""}`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  exportAllJobsToPdf: (
    token,
    key,
    filtersString = {},
    isArchive = "False",
    sortType = "sort"
  ) => {
    filtersString =
      typeof filtersString === "object"
        ? getFiltersString(filtersString)
        : filtersString;

    return axios.get(
      `${apiUrl}/api/jobs/jobs/pdf/?${
        key ? `${sortType}=${key}` : ""
      }&is_archived=${isArchive}${filtersString ? filtersString : ""}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getNearJobs: (
    token,
    tenantUuid,
    employeeUuid,
    dateString = "",
    { filters = {}, page = 1, size = 5, sort = "distance" } = {}
  ) => {
    const headers = { headers: { Authorization: `JWT ${token}` } };
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/filtered-jobs/${tenantUuid}/${employeeUuid}/` +
        `?sort=${sort}&page=${page}&page_size=${size}${
          dateString
            ? `&start_date__gte=${dateString}&start_date__lte=${dateString}`
            : ""
        }` +
        `&is_full=False&is_archived=False${filtersString}`,
      headers
    );
  },
  getNearestJobs: (
    token,
    tenantUuid,
    employeeUuid,
    dateString = "",
    { filters = {}, page = 1, size = 5, sort = "distance" } = {}
  ) => {
    const headers = { headers: { Authorization: `JWT ${token}` } };
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/nearest/${employeeUuid}/` +
        `?sort=${sort}&page=${page}&page_size=${size}${
          dateString
            ? `&start_date__gte=${dateString}&start_date__lte=${dateString}`
            : ""
        }` +
        `&is_full=False&is_archived=False&already_applied=False${filtersString}`,
      headers
    );
  },
  getNearEmployees: (
    token,
    tenantUuid,
    jobUuid,
    key,
    limit,
    page,
    isArchive = "False",
    filtersString,
    sortType = "sort"
  ) => {
    filtersString =
      typeof filtersString === "object"
        ? getFiltersString(filtersString)
        : filtersString;

    return axios.get(
      `${apiUrl}/api/users/nearest/ambassador/${tenantUuid}/${jobUuid}/?${
        key ? `${sortType}=${key}&` : ""
      }${limit ? `&page_size=${limit}` : ""}${page ? `&page=${page}` : ""}${
        filtersString ? filtersString : ""
      }`,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  getSingleJob: (token, tenantUuid, jobUuid) => {
    return axios.get(
      `${apiUrl}/api/jobs/sub-job-with-archived/light/${tenantUuid}/?&page_size=1&page=1&uuid=${jobUuid}`,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  createJob: (token, tenantUuid, projectUuid, data) => {
    return axios.post(
      `${apiUrl}/api/jobs/sub-job-creation-json/${tenantUuid}/${projectUuid}/`,
      data,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  newCreateJob: (token, data) => {
    return axios.post(`${apiUrl}/api/jobs/jobs/`, data, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  setArchiveStatusJobs: (token, tenantUuid, data, filters, action) => {
    let payload = {
      is_archived: action === "archive" ? "True" : "False",
    };

    if (data.length > 0) {
      payload.uuid = data.map((item) => item.uuid);
    }
    return Promise.resolve(
      axios.put(
        `${apiUrl}/api/jobs/bulk/update/job/${tenantUuid}/?${
          filters ? filters : ""
        }`,
        payload,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      )
    );
  },
  /**
   *
   * @param {string} token
   * @param {string} tenantUuid
   * @param {string} key
   * @param {number} limit
   * @param {number} page
   * @param {object} exportConfig {columns:"ColumnsSeparateWithCommas", columnHeaders: "ColumnsNamesSeparateWithCommas"}
   */
  getAllJobs2: (
    token,
    tenantUuid,
    key,
    limit = 25,
    page = 1,
    filtersString,
    isArchive = "False",
    exportConfig
  ) => {
    //Build request headers
    let requestHeaders = { headers: { Authorization: `JWT ${token}` } },
      isExport = exportConfig && exportConfig.columns,
      downloadParams = "";

    if (isExport) {
      requestHeaders.responseType = "blob";
      downloadParams = `&is_excel_export=True&excel_properties=${exportConfig.columns}`;
      if (exportConfig.columnHeaders)
        downloadParams += `&excel_column_headers=${exportConfig.columnHeaders}`;
    }

    return axios.get(
      `${apiUrl}/api/jobs/application-list/all/` +
        `${tenantUuid}/?sort=${key}&is_archived=False&is_completed=True&is_approved=True&is_exported=True&page_size=${limit}&page=${page}${
          filtersString ? filtersString : ""
        }${isExport ? downloadParams : ""}`,
      requestHeaders
    );
  },
  /**
   * @param {string} columns Eg field1,field2, ... fieldn
   * @param {string} columnHeaders Eg name1,name2, ... namen
   */
  exportJobs: (token, tenantuuid, columns = "", columnHeaders) => {
    return axios.get(
      `${apiUrl}/api/jobs/application-list/all/${tenantuuid}/` +
        `?is_excel_export=True` +
        `&excel_properties=${columns}` +
        `&excel_column_headers=${columnHeaders}`,
      {
        responseType: "blob",
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getMember: (token, jobUuid, filtersString = "", options = {}) => {
    const { excludingArchived = true } = options;

    return axios.get(
      `${apiUrl}/api/jobs/member/${jobUuid}/?${
        excludingArchived ? "is_archived=False" : ""
      }${filtersString}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getJob: (token, tenantUuid, jobUuid) => {
    return axios.get(`${apiUrl}/api/jobs/job/${tenantUuid}/${jobUuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  setJob: (token, tenantUuid, jobUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/job/${tenantUuid}/${jobUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  bulkUpdateJobs: (token, payload) => {
    return axios.put(`${apiUrl}/api/jobs/jobs/bulk-update/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getJobsLightByEmployee: (
    token,
    tenantUuid,
    employeeUuid,
    filtersString = ""
  ) => {
    return axios.get(
      `${apiUrl}/api/jobs/application-list/all-light/${tenantUuid}/?sort=start_date&completed_status=job_accepted&is_archived=False&employee__uuid=${employeeUuid}${filtersString}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getJobsLightByEmployeeWithoutCompleted: (
    token,
    tenantUuid,
    employeeUuid,
    filtersString = ""
  ) => {
    return axios.get(
      `${apiUrl}/api/jobs/application-list/all-light/${tenantUuid}/?sort=start_date&is_archived=False&employee__uuid=${employeeUuid}${filtersString}&invalid_state__in=null`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  setBriefingDoc: (token, jobUuid, payload) => {
    return axios.put(`${apiUrl}/api/jobs/${jobUuid}/briefing-doc/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getInvitations: (token, tenantUuid, jobUuid, options = {}) => {
    const { excludingArchived = true, filters = {} } = options;
    const filtersString =
      typeof filters === "string" ? filters : getFiltersString(filters);
    return axios.get(
      `${apiUrl}/api/jobs/application-list/all/${tenantUuid}/?job__uuid=${jobUuid}${
        excludingArchived ? `&is_archived=False` : ""
      }${filtersString}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  sendInvitation: (token, jobUuid, employeeUuid, payload) => {
    return axios
      .post(
        `${apiUrl}/api/jobs/application/invite/${jobUuid}/${employeeUuid}/`,
        payload,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      )
      .then((response) =>
        employeeService
          .setEmployee(token, employeeUuid, {
            last_contacted_at: moment().format("DD-MM-YYYY HH:mm"),
          })
          .then(() => response)
      );
  },
  getCandidates: (token, tenantUuid, jobUuid, options = {}) => {
    const {
      filters = {},
      isQualified = false,
      pagination = {},
      sortBy = "distance",
      sortByAvailability = false,
    } = options;
    const filterString = getFiltersString(filters);
    const sortByValidOptions = [
      "distance",
      "weekly_hours_worked",
      "experience",
      "contract_hours_difference",
      "-distance",
      "-weekly_hours_worked",
      "-experience",
      "-contract_hours_difference",
    ];
    const invalidSortByWithMultipleJobUuids = ["distance"];
    const isSortBy = sortByValidOptions.includes(sortBy);
    const areMultipleJobs = Array.isArray(jobUuid);

    return axios({
      method: areMultipleJobs ? "post" : "get",
      url: `${apiUrl}/api/users/candidates/${tenantUuid}${
        !areMultipleJobs ? `/${jobUuid}` : ""
      }/?${isSortBy ? "sort_by" : "sort"}=${
        sortByAvailability && isSortBy
          ? sortBy.indexOf("-") === 0
            ? "-availability"
            : "availability-"
          : ""
      }${
        areMultipleJobs && invalidSortByWithMultipleJobUuids.includes(sortBy)
          ? "full_name"
          : sortBy
      }&is_qualified=${isQualified ? "True" : "False"}${
        pagination.page ? "&page=" + pagination.page : ""
      }${
        pagination.size ? "&page_size=" + pagination.size : ""
      }${filterString}`,
      data: {
        job_uuids: jobUuid,
      },
      headers: { Authorization: `JWT ${token}` },
    }).then((response) => {
      const candidates = response.data.results;

      return { candidates, response };
    });
  },
  getJobApplications(token, tenantUuid, jobUUid, options = {}) {
    const {
      sort = "distance",
      pagination = {},
      filters = {},
      registeredOnly = false,
    } = options;
    const filterString = getFiltersString(filters);

    return axios
      .get(
        `${apiUrl}/api/users/${
          registeredOnly ? "employees" : "applicants"
        }/${tenantUuid}/${jobUUid}/?sort=${sort}${
          pagination.page ? "&page=" + pagination.page : ""
        }${
          pagination.size ? "&page_size=" + pagination.size : ""
        }${filterString}`,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      )
      .then((response) => {
        const applications = response.data.results;

        return { applications, response };
      });
  },

  getExternalCandidates: (token, tenantUuid, jobUuid, options = {}) => {
    const {
      filters = {},
      isQualified = false,
      pagination = {},
      sortBy = "distance",
      sortByAvailability = false,
    } = options;

    const filterString = getFiltersString(filters);

    return axios({
      method: "get",
      url: `${apiUrl}/api/shared/candidates/${jobUuid}/${
        pagination.page ? "?page=" + pagination.page : ""
      }${
        pagination.size ? "&page_size=" + pagination.size : "" + "/"
      }${filterString}`,
      headers: { Authorization: `JWT ${token}` },
    }).then((response) => {
      const candidates = response.data.results;

      return { candidates, response };
    });
  },

  inviteEmployees(token, jobUUid, employeeUuids, expirationTime) {
    return axios
      .post(
        `${apiUrl}/api/jobs/bulk/invite/${jobUUid}/`,
        { expiration_time: expirationTime, employee_uuids: employeeUuids },
        {
          headers: { Authorization: `JWT ${token}` },
        }
      )
      .then((response) => {
        return Promise.all(
          employeeUuids.map((employeeUuid) =>
            employeeService
              .setEmployee(token, employeeUuid, {
                last_contacted_at: moment().format("DD-MM-YYYY HH:mm"),
              })
              .catch((err) => {
                return {};
              })
          )
        ).then(() => response);
      })
      .then((response) => {
        const result = response.data.results;

        return { result, response };
      });
  },
  getJobApplicationsFromJobDetails(token, jobUuid, options = {}) {
    const { japUuid, archived = undefined } = options;

    return axios.get(
      `${apiUrl}/api/jobs/application/${jobUuid}/?${
        japUuid ? `uuid=${japUuid}` : ""
      }${
        typeof archived !== "undefined"
          ? `&is_archived=${archived ? "True" : "False"}`
          : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getJapWithSharedEmployeesFromJobDetails(token, jobUuid, options = {}) {
    const { japUuid, archived = undefined } = options;

    return axios.get(
      `${apiUrl}/api/jobs/application/shared-employees/${jobUuid}/?${
        japUuid ? `uuid=${japUuid}` : ""
      }${
        typeof archived !== "undefined"
          ? `&is_archived=${archived ? "True" : "False"}`
          : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createSharedJobApplication(token, tenantUUid, payload) {
    return axios.post(
      `${apiUrl}/api/jobs/shared/proposal/${tenantUUid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getJobRequests: (
    token,
    tenantUuid,
    filtersString,
    key,
    sortType = "sort",
    startDate = null
  ) => {
    let headers = { headers: { Authorization: `JWT ${token}` } };

    return axios.get(
      `${apiUrl}/api/jobs/shared/request/${tenantUuid}/?${
        key ? `${sortType}=${key}&` : ""
      }${startDate ? `start_date__gte=${startDate}&` : ""}${
        filtersString ? filtersString : ""
      }`,
      headers
    );
  },
  proposeSharedJobApplication: (token, payload) => {
    return axios.post(`${apiUrl}/api/shared/job-application/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  /**
   * @param {string} token
   * @param {string} proposalUuid
   * @param {"accepted" | "rejected" | "archive" | "unarchive" | "withdrawn"} status
   */
  changeProposalStatus(token, proposalUuid, status) {
    return axios.put(
      `${apiUrl}/api/shared/job-application/${proposalUuid}/`,
      status === "archive"
        ? { is_archived: "True" }
        : status === "unarchive"
        ? { is_archived: "False" }
        : { status },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTableJobs(
    token,
    {
      sortKey = "start_date",
      pagination = { page: 1, size: 25 },
      filters = {},
      exportOptions = null,
      isArchive = false,
    },
    todayDate,
    isJobCreation,
  ) {
    const filtersString = getFiltersString(filters);

    let queryparam = ''
    if(isJobCreation){
      queryparam = `&date_created__gte=${todayDate}`
    }
    return axios.get(
      `${apiUrl}/api/jobs/jobs/?sort=${sortKey}${
        pagination
          ? `&page=${pagination.page}&page_size=${pagination.size}`
          : ""
      }&is_archived=${isArchive ? "True" : "False"}${filtersString}${
        exportOptions
          ? `&is_excel_export=True&excel_properties=${exportOptions.columns}&excel_column_headers=${exportOptions.columnsNames}`
          : ""
      }${queryparam}`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: exportOptions ? "blob" : "json",
      }
    );
  },
  cancelJob(token, payload) {
    return axios.post(`${apiUrl}/api/jobs/jobs/cancel/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  cancelJobsInBulk(token, payload) {
    return axios.put(`${apiUrl}/api/shared/job/bulk/cancel/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getSingleJobFreeFields: (token, uuid) => {
    return axios.get(
      `${apiUrl}/api/jobs/job-free-fields/${uuid}/?is_archived=False`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getJobMaterials: ({ token }) => {
    return axios.get(`${apiUrl}/api/jobs/tenant-items/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getJobApplicationMaterials: ({ token, jap_uuid }) => {
    return axios.get(`${apiUrl}/api/jobs/job-application-items/${jap_uuid}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  assignMaterialsToEmployee({ token, payload, jap_uuid }) {
    return axios.post(
      `${apiUrl}/api/jobs/job-application-items/${jap_uuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  updateJapMaterial({token, item_jap_uuid, payload}) {
    return axios.put(`${apiUrl}/api/jobs/job-application-items/update/${item_jap_uuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    })
  },
  deleteJapMaterials({ token, payload }) {
    return axios.delete(`${apiUrl}/api/jobs/job-application-items/delete/`, {
      headers: { Authorization: `JWT ${token}` },
      data: payload,
    });
  },
  completeItemDistribution(token, tenant_uuid, shift_uuid, payload) {
    return axios.put(`${apiUrl}/api/jobs/declaration/${tenant_uuid}/${shift_uuid}/item_distribution/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    })
  },
  completeItemGathering({token, tenant_uuid, shift_uuid, payload}) {
    return axios.put(`${apiUrl}/api/jobs/declaration/${tenant_uuid}/${shift_uuid}/item_gathering/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    })
  },
  upadateMangerSignOff({token, shift_uuid, payload}) {
    return axios.put(`${apiUrl}/api/jobs/check-in-check-out/update/${shift_uuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    })
  },
  updateEmployeeSignOff({token, shift_uuid, payload}) {
    return axios.put(`${apiUrl}/api/jobs/check-in-check-out/update/${shift_uuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    })
  }
};

export default jobService;
