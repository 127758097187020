import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useCancellablePromise } from "hooks";
import { SchedulerService, jobService } from "services";

/**
 * @param {{
 *  sortBy?: string;
 * }} options
 * @returns {{
 *  loading: boolean;
 *  totalCount: number;
 *  employees: {
 *    uuid: string;
 *    full_name: string;
 *    contract: {};
 *    job_applications: any[];
 *    contract_hours_difference: number;
 *    contracts: any[];
 * }[];
 *  getEmployees: (from: string, to: string, options?: {
 *    filters?: {
 *      [key: string]: { key: string; prefix?: string; suffix?: string; value: string };
 *    };
 *  }) => void;
 *  loadNext: (from: string, to: string, options?: {
 *    filters?: {
 *      [key: string]: { key: string; prefix?: string; suffix?: string; value: string };
 *    };
 *  }) => void;
 *  editList: (
 *    employees: {
 *       uuid: string;
 *       full_name: string;
 *       contract: {};
 *       job_applications: any[];
 *       contract_hours_difference: number;
 *    }[]
 *  ) => void;
 * }}
 */
export function useScheduler({ sortBy } = {}) {
  const user = useSelector((state) => state.user.data);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(25);
  const [page, setPage] = useState(1);
  const [employees, setEmployees] = useState([]);

  const getEmployees = useCallback(
    /**
     * @param {string} from
     * @param {string} top
     * @param {{
     *  filters?: {
     *    [key: string]: { key: string; prefix?: string; suffix?: string; value: string };
     *  };
     * }} options
     */
    (from, to, { filters } = {}) => {
      if (!user) return;

      setLoading(true);
      setEmployees([]);
      setPage(1);

      return SchedulerService.getEmployeesBetween(
        user && user.token,
        from,
        to,
        {
          sortBy,
          filters: {
            ...filters,
            archived: {
              key: "is_archived",
              value: "False",
            },
          },
          size: 50,
          page: 1,
        }
      ).then((response) => {
        setEmployees(response.results);
        setLoading(false);
        setTotalCount(response.total_count);
        setPage(prevPage => prevPage + 1)
      });
    },
    [user, sortBy]
  );

  const loadNext = useCallback(
    /**
     * @param {string} from
     * @param {string} to
     * @param {{
     *  filters?: {
     *    [key: string]: { key: string; prefix?: string; suffix?: string; value: string };
     *  };
     * }} options
     */
    (from, to, { filters } = {}) => {
      if (loading || !user) return;

      setLoading(true);

      return SchedulerService.getEmployeesBetween(
        user && user.token,
        from,
        to,
        {
          sortBy,
          filters: {
            ...filters,
            archived: {
              key: "is_archived",
              value: "False",
            },
          },
          size: 50,
          page,
        }
      ).then((response) => {
        setTotalCount(response.total_count)
        setEmployees(prevEmployees => [...prevEmployees, ...response.results]);
        setLoading(false);
        setPage(prevPage => prevPage + 1);
      });
    },
    [page, user, loading, employees, sortBy]
  );

  const editList = useCallback(
    /**
     * @param {{
     *    uuid: string;
     *    full_name: string;
     *    contract: {};
     *    job_applications: any[];
     *    contract_hours_difference: number;
     * }[]} employees
     */
    (employees) => {
      setEmployees(employees);
    },
    []
  );

  return { loading, employees, totalCount, getEmployees, loadNext, editList };
}

export function useGetEmployeeAvailability() {
  const { cancellablePromise } = useCancellablePromise();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);

  const getEmployeeAvailability = useCallback(
    (employeeUuid, jobUuids) => {
      setLoading(true);

      return cancellablePromise(
        Promise.all(
          jobUuids.map((jobUuid) => {
            return jobService.getCandidates(
              user.data.token,
              user.data.tenant.uuid,
              jobUuid,
              {
                sortByAvailability: false,
                pagination: {
                  page: 1,
                  size: 1,
                },
                isQualified: false,
                filters: {
                  employeeUuid: {
                    key: "uuid",
                    suffix: "",
                    value: employeeUuid,
                  },
                },
              }
            );
          })
        )
      ).then((responses) => {
        setLoading(false);

        return responses.map((response, index) => {
          const {
            percentage_false,
            percentage_true,
          } = response.response.data.results[0];

          return {
            job_uuid: jobUuids[index],
            percentage_false,
            percentage_true,
          };
        });
      });
    },
    [user]
  );

  return { getEmployeeAvailability, loading };
}
