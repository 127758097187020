import { SET_PAGE_SCHEDULER, SET_SCHEDULER_PREFERENCES, SET_WEEK_CHANGE } from "redux/types";

/**
 * Save the scheduler preferences
 * @param {{ [key: string]: any }} newPreferences
 */
export function setSchedulerPreferences(newPreferences) {
  return { type: SET_SCHEDULER_PREFERENCES, data: newPreferences };
}


export function setWeekChange(data){
  return { type: SET_WEEK_CHANGE, data }
}

export function setPageSchedulerAction(data) {
  return {type: SET_PAGE_SCHEDULER, data}
}